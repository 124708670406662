.page-padding {
  padding: 8px 16px;
}
.page-padding-x {
  padding-left: 16px;
  padding-right: 16px;
}
.page-padding-y {
  padding-top: 8px;
  padding-bottom: 8px;
}
