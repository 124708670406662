body {
  margin: 0;
  padding: 0;
  color: #333333;
  font-size: 16px;
  line-height: 1 !important;
  font-family: "PingFang SC", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

*,
::before,
::after {
  box-sizing: border-box;
}
